import { Component, ElementRef, Input, ViewChild, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forkJoin, map } from 'rxjs';
import { FileService } from 'src/app/core/services/files.service';

@Component({
  selector: 'nobis-input-type-file',
  templateUrl: './input-type-file.component.html',
  styleUrls: ['./input-type-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTypeFileComponent),
      multi: true,
    },
  ],
})
export class InputTypeFileComponent implements ControlValueAccessor {
  @Input() status!: 1 | 0;
  @Input() label!: string;
  @Input() multi = true;
  @Input() hint = ' Admite .jpg, .png, .heic, .pdf,<strong> hasta 5 MB y 5 archivos.</strong>';
  @Input() size = 4;
  @Input() disabled? = false;
  private fileService = inject(FileService);
  private acceptTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif'];
  private maxFileSizeKilobytes = 100000;
  protected selectedFiles: File[] = [];
  protected accept = this.acceptTypes.join(',');
  protected color = '#D9D9D9';

  @ViewChild('fileInput') fileInput!: ElementRef;

  protected onFileSelected(event: Event): void {
    const fileList = (event.target as HTMLInputElement).files;

    this.inputInvalid();

    if (this.isFileUploadValid(fileList)) {
      this.selectedFiles = [];
      return;
    }

    const fileExtensionException = ['heic', 'heif'];

    Array.from(fileList!).forEach((file: File) => {
      const fragmentedName = file.name.split('.');
      const fileExtension = fragmentedName[fragmentedName.length - 1];

      if (
        !fileExtensionException.some(exception => exception === fileExtension.toLowerCase()) &&
        !this.fileService.isFileTypeAcceptable(this.acceptTypes, file.type)
      ) {
        return;
      }

      if (!this.fileService.isFileSizeAcceptable(this.maxFileSizeKilobytes, file.size)) {
        return;
      }

      this.listFile(file);
    });

    this.fileInput.nativeElement.value = null;
  }

  protected listFile(file: File) {
    this.selectedFiles.push(file);

    this.updateBase64();
  }

  private updateBase64(): void {

    const files = this.selectedFiles.map(file =>
      this.fileService.fileToBase64(file).pipe(
        map(base64 => ({
          name: file.name,
          base64,
        }))
      )
    );

    forkJoin(files).subscribe({
      next: args => {
        this.onChange(args);
      },
    });
  }

  protected togglePasswordVisibility(event: Event): void {
    event?.stopPropagation();
  }

  protected removeFile(index: number): void {
    if (index < 0) {
      return;
    }

    this.selectedFiles.splice(index, 1);
    this.onChange(this.selectedFiles);
  }

  protected downloadDocument(file: File): void {
    this.fileService.downloadFile(file, file.name, file.type);
  }

  protected inputInvalid(): boolean {
    return this.selectedFiles.length > this.size;
  }

  private isFileUploadValid(fileList: FileList | null): boolean {
    return !fileList || !fileList.length || fileList.length > 5;
  }

  private _value: any;

  get value(): any {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  // Métodos de ControlValueAccessor
  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (_: any) => {};
  private onTouched = () => {};
}
