import { Component, Input } from '@angular/core';

@Component({
  selector: 'nobis-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title?: string;
  @Input() content?: string;
  @Input() type: 'info' | 'alert' = 'info';
  @Input() imgWidth? = 58;
  @Input() imgHeight? = 58;
}
