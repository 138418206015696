import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AsideModule } from './components/aside/aside.module';
import { ButtonStateModule } from './components/button-state/button-state.module';
import { ButtonModule } from './components/button/button.module';
import { HeaderModule } from './components/header/header.module';
import { InputTypeFileModule } from './components/input-type-file/input-type-file.module';
import { LoadingModule } from './components/loading/loading.module';
import { RequiredElementModule } from './components/required-element/required-element.module';
import { SlideButtonModule } from './components/slide-button/slide-button.module';
import { TooltipNavModule } from './components/tooltip/tooltip.module';
import { CardModule } from './components/card/card.module';


@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    ButtonModule,
    SlideButtonModule,
    HeaderModule,
    AsideModule,
    TooltipNavModule,
    ButtonStateModule,
    LoadingModule,
    InputTypeFileModule,
    RequiredElementModule,
    CardModule
  ],
})
export class SharedModule { }

