import { createSelector } from '@ngrx/store';
import { AuthenticationState } from '../reducers/authentication.reducers';

const selectFeature = (state: any) => state.auth;

export const selectIsAuthenticated = createSelector(selectFeature, state => state.isAuthenticated);

export const selectToken = createSelector(
  selectFeature,
  state => state.user?.user.access_token.token
);

export const selectIsLogginIn = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.isLoggingIn
);

export const selectTokenGecros = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user.user.gecros_token.token
);

export const selectIsLogginError = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.response?.error
);

export const selectProfileUser = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.nombreAfiliado
);
export const selectIsOwner = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.parentesco === 'TITULAR'
);
export const selectProfileNameUser = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.nombreAfiliado.split(' ')[0]
);

export const selectUserBenGrId = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.benGrId as number
);
export const selectUserBenId = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.benId as number
);

export const selectIsCompany = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.agent.isCompany
);

export const selectProvinceId = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.provincia.id
);

export const selectPlanId = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.plan.id
);

export const selectPlanName = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.plan.name
);

export const selectUserDNI = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate.documento
);

export const selectUser = createSelector(
  selectFeature,
  (state: AuthenticationState) => state.user?.user.affiliate
);