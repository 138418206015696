<div class="card" [ngClass]="{ info: !title }">
  <div [ngSwitch]="type">
    <div *ngSwitchCase="'info'">
      <img [width]="imgWidth" [height]="imgHeight" src="./../../../../assets/svg/info.svg" alt="" />
    </div>
    <div *ngSwitchCase="'alert'">
      <img
        [width]="imgWidth"
        [height]="imgHeight"
        src="./../../../../assets/svg/alert.svg"
        alt="" />
    </div>
  </div>

  <div class="text">
    <h1 *ngIf="title">
      <strong>{{ title }}</strong>
    </h1>

    <div [innerHTML]="content"></div>
    <ng-content></ng-content>
  </div>
</div>
